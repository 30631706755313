.ant-layout {
    background: transparent;
}

.ant-layout-sider {
    background-color: $menu-dark-bg;
}




