.side-nav {
  height: calc(100vh - #{$header-height});
  position: fixed;
  top: $header-height;
  box-shadow: $side-nav-box-shadow;
  background-color: $white;
  z-index: $zindex-navbar;

  @include print {
    display: none;
  }

  .ant-layout-sider-children{
    background-color: #13243c;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    line-height: 2.5rem;
    height: 2.5rem;
  }
  .ant-menu-item-group-title {
    background-color: #13243c !important;
    color: #fff;
    margin: 0 !important;
  }

  .ant-menu-item,
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-item-group-list {
    background-color: #13243c !important;
    color: #fff;
    margin: 0;
  }

  &.side-nav-dark {
    background-color: $menu-dark-bg;
  }

  .hide-group-title {
    .ant-menu-item-group-title {
      display: none;
    }
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 1rem !important;
  }

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    transform: scaleY(1);
    opacity: 0 !important;
  }

  .ant-menu-item a::before {
    opacity: 0;
    border-left: 4px solid #0091ce;
  }

  .ant-menu-item-selected.ant-menu-item a::before {
    transform: scaleY(1);
    opacity: 1;
    color: #fff !important;
  }

  .ant-menu-item-selected {
    color: #fff !important;
    background-color: #0066a1 !important;
  }

  .ant-menu-item span{
    color: #fff !important;
    z-index:999;
  }
  .ant-menu-item a:hover {
    background-color: #0066a1 !important;
  }

  .ant-menu li:first-child{
    margin-top: .5rem !important;
  }
}

.mobile-nav-menu {
  height: calc(100vh - #{$header-height} - 10px);
}
