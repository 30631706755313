@import "../node_modules/antd/dist/antd.css";
@import "assets/scss/global";
@import "assets/scss/utilities/utilities";

:root {
  --var-text-purple: #0066a1;
  --var-text-black: #000000;
  --var-btn-purple: #0066a1;
}

.text__color__purple {
  color: var(--var-text-purple);
}
.text__color__black {
  color: var(--var-text-black);
}
.btn__purple {
  background-color: var(--var-btn-purple) !important;
  border: 0 !important;
}
.text-black-100 {
  color: #000;
}

.opacity-20 {
  opacity: 0.2;
}
